<template>
  <el-upload
    class="upload-demo"
    action=""
    :limit="1"
  :before-upload="beforUpload">
    <el-button type="primary">导入</el-button>
  </el-upload>
</template>

<script>
  export default {
    data() {
      return {
        fileList: []
      };
    },
    methods: {
      beforUpload(file) {
        console.log(file);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .down-export .upload-demo {
    position: relative !important;
    /deep/ .el-upload-list {
      position: absolute !important;
      bottom: -25px;
      left: -50px;
    }
  }

</style>
